@import "sass-lib";
@media (min-width: 0px) and (max-width: 767px) {

	.wrapper{ padding: 0 0 0 20px; margin: 0 20px; }
	header{
		height: 50px;
		.logo{
			width: 230px; object-fit: cover; height: 50px; left: -20px;
			img{  }
		}
		.toggle-mobile{ padding-top: 10px; }
		.wrapper{ margin: 0 20px; }
		.right-header{
			width: 100%;
		}
		.right-header{
			nav{
				> ul{
					/*height: 350px;*/
					ul{
						overflow: auto;
						/*max-height: 100px; */
						background: #fff; padding: 0 10px 0 0; margin: 15px 0 0;
					}
				}
			}
		}
		.top-mobile{
			.burgermenu-close{
				width: 20px;
				img{ width: 100%; }
			}
		}
	}

	.btn{
		&.rounded{ line-height: 50px; font-size:18px;  }
		&.rounded-arr{
			font-size: 12px; line-height: 30px; padding: 0 35px 0 15px;
			&:after{ right:10px; width: 15px; background-size: 100%; }
			&.gr{
				&:after{ background-size:100%; }
			}
			&.back{
				padding: 0 15px 0 35px;
				&:after{ left:10px; background-size: 100%; }
			}
			&.green{
				&:after{ background-size:100%; }
			}
		}

	}
	.btn-seedetail{
		font-size: 12px; padding-right: 30px;
		&:after{ width:20px; background-size: 100%; right: 5px; }
	}
	.list-download{ padding: 20px 30px 20px 80px; }
	.home{
		&.about{
			.wrapper{ padding: 0 0 0 20px; }
			&:after{ right: -20%; bottom: -10%; width: 277px; height: 277px; background-size: 100%; }
		}
		&.register{
			.wrap-list-formulir{
				.list{ float: none; width: 100%; }
				p{ float: none; }
				a{ left: 73px; }
			}

		}
		&.news{

			.news-wrap{
				margin: 20px 0 0;
				figure{
					height: 150px;
					img{ height: 100%; object-fit: cover; }
				}
			}
			.after_clear{
				.left,.right{ width: 100%; float: none; }
			}
			.wrap-news{
				position: relative; padding-bottom: 40px;
				.btn{
					position: absolute; bottom: 0; left: 0; margin-top: 0;  margin: 0 auto; right: 0;
					width: 130px; text-align: center;
				}
			}
		}
	}
	.banner-home{
		h1{ font-size: 20px; line-height: 28px; }
		p{ font-size: 14px; line-height: 22px; }
	}
	.banner-middle{
		h5{ font-size: 9px; margin-bottom: 10px; }
		h1{ font-size: 20px; line-height: 29px; }
	}
	.video-home{
		.thumb-video{
			height: 190px;
			.ic-play{
				width: 40px; height: 40px;
				small{ font-size: 5px; }
			}
		}
	}
	.two-box{
		.left,.right{ float: none; display: block; width: 100%; }
	}
	.middle{
		padding: 40px 0;
		.linesec-medium{ left: -22px; }
		&.landing-detail{ padding-top: 100px; }
	}
	.share-detail{
		width: 250px; height: 50px; display: table;
		span{
			@include boxSizing(border-box); display: table-cell;vertical-align: middle; border-right: 2px solid #eee;
			width: 40%;
		}
		a{display: table-cell; vertical-align: middle; }
	 }
	.two-box-middle{
		h1{ font-size: 18px; line-height: 26px; }
		h2{ font-size: 20px; line-height: 28px; }

		&.detail{
			.right,.left{ float: none; display: block; width: 100%; text-align: left; }
			.left{ margin-bottom: 25px; }
		}
		.date{ text-align: left; font-size: 46px; }

		.monthyear{ text-align: left; display: block; margin-bottom: 25px; font-size: 18px; }
	}
	.desc-upload{ font-size: 14px; line-height: 20px; }
	.input-form{
		label {
			left: 15px;
		}
		.option-form{
			width: 48%; margin-right: 4%;
			&:nth-of-type(2n){ margin-right: 0; }
			span{ width: 100%; font-size: 14px; line-height: 28px; word-break: break-word; padding: 0 5px; @include boxSizing(border-box); }
		}
		.btn.rounded{ width: 100%; }
		.label-tittle{ line-height: 20px; font-size: 14px; margin:20px 0 10px; }
		.col{
			&.half{
				float: none; display: inline-block; width: 100%; margin-right: 0;
				input[type='text']{ height: 60px; }
			}
		}
		.chk-box{
			label{ font-size: 14px; padding-left: 35px; line-height: 22px; }
			input[type='checkbox']{
				width: 18px; height: 18px; background-size: 100%;
				&:checked{ background-size:100%; }
			}
		}
		textarea {font-weight: 200;}
		&.prosedur{
			textarea{ padding: 15px; font-size: 14px; }
		}
		input[type='text'],
		input[type='email'],
		input[type='number']{ font-size:14px;  padding:25px 15px 0 15px; }
		@include placeholder{ font-size: 14px; }
		input[type='file'],
		.field .row-option,
		select{
			padding-left: 15px;
			background-size: 24px;
		}
		textarea{ padding-left:15px; padding-right:15px; font-size: 14px;}
		.fileinput {
			span {left: 15px;}
		}
		.alert {
			padding: 5px 15px 0 15px; font-size: 12px;
			@include boxSizing(border-box);
		}
		.field .row-option{
			margin-bottom:0;
		}
		.row-option{
		    flex-wrap: wrap;
		    margin-bottom:-10px;
			label{
				width:50%;
				padding-right:20px;
				@include boxSizing(border-box);
				margin-right:0;
				margin-bottom:10px;
			}
		}
		.field{
			&::after{
				display: none;
			}
		}
		input.date-picker{
			background-size: 20px;
			background-position: 95% 67%;
		}
		select{
			background-size: 18px;
			background-position-y: 60%;
		}
	}
	.wrap-acc-middle{
		.inner{ display: block; }
		.img-acc{
			display: block; width: 100%; margin-bottom: 25px;
			img{ width: 100%;  }
		}
		.text{ display: block; width: 100%; padding-left: 0; }
	}
	.end-sec{ margin: 40px -1000px 40px -20px; }
	.page404{
		height: auto; position: relative;
		.center{
			 width: auto;margin: 0 40px; top: 0; @include transform(translateY(0));
			 padding: 100px 0 40px; position: relative;
		}
		.wrap-btn{
			a{ display: block; width: 150px; margin: 5px auto; }
		}
		.line-bot{ min-height: 100px; position: relative; }
	}
	.thankyou-page {
		height: auto; position: relative;
		.center{
			 width: auto;margin: 0 40px; top: 0; @include transform(translateY(0));
			 padding: 100px 0 40px; position: relative;
		}
		h5 {font-size: 24px;}
		p {margin: 0 0 20px 0;}
	}
	.underconst{
		.logo {margin: 0 0 0 -30px;}
		&:after{ width: 200px;		}
		.wrapper{ padding: 0 0 0 20px; margin: 0 20px; }
		.text {
			margin-bottom: 50px;
			.wrap-text {
				padding: 0;
			}
		}
		.end-sec{ margin: 40px -1000px 40px -20px;  }
		.line-body-sec{ left: -20px; }
		.linesec-medium{ left: -22px; }
		.logo{ width: 200px; padding-top: 20px; }
		h1{ font-size: 28px; line-height: 40px; word-break: break-word; }
		h5{ font-size: 14px; line-height: 22px; }
		p{ font-size: 14px; line-height: 22px; }
		.tow-column{
			padding: 0 0 20px 0;
			b{ line-height:22px;  }
			.col{ width: 100%; margin-right: 0; }
		}
	}
	footer{
		.sub{
			h5{ font-size: 12px; }
			p{ font-size: 12px; line-height: 18px; }
			.sub-footer{
				width: 45%; float: left;
				&:first-child{ width:100%; float: none; display: block; margin-bottom: 40px; }
			}
		}

		.bot{
			p{ font-size:10px; font-weight: bold; text-align: left; padding-right: 75px;	  }
		}

	}

}




@media (min-width: 0px) and (max-width: 479px) {

	header{
		.toggle-mobile{
			.box-search{
				width: 15px; padding-top: 10px; margin-right: 10px; z-index: 9; padding-bottom: 10px;
				.search-drop{ left: -290px; top:50px; }
			}
			.burgermenu{ width:20px; padding-top: 8px;  }
		}
		.right-header{
			nav{
				> ul{
					> li{
						a{ font-size: 14px;font-weight: bold;}
					}
					ul{
						li{
							a{ font-size: 12px; line-height: 20px;  }
						}
					}
				}
			}
		}
		.toggle-mobile .box-search .search-drop{
			 height:45px; width: 200px; left: -170px;
			 input[type='text']{
			 	font-size: 14px; border: 1px solid #fff;
			 	&:focus{ border-bottom:1px solid #009be0; }
			 }
		}
	}
	.home{
		&.news{
			.wrap-news{
				figure{ width: 100%; float: none; }
				.inner{ float: none; display: block; width: 100%; padding: 20px 15px; }
			}
		}
	}
	.list-download{
		padding: 15px 30px 15px 80px;
		figure{ width: 65px; }
	}
	.paging{
		a,span{ font-size: 14px; padding: 10px 12px; }
	}
	.two-box-middle{
		p{ font-size: 14px; line-height: 22px; }
		&.table{
			display: block;
			.left{
				width: 100%; display: block;
				figure{
					img{  }
				}
			}
			.right{
				width: 100%; display: block; padding: 20px 0;
				h5{ font-size: 14px; line-height: 22px; }

			}
		}
	}
	.banner-middle{
		&.page-search{
			figure{
				img{ height: 300px; }
			}
		}
	}

	footer{
		.sub{
			a{ font-size: 12px; margin-bottom: 10px; }
			.sub-footer{
				&:last-child{
					a{ margin:0 5px 0 0; }
				}
			}
		}

	}
}
@media all and (max-width:365px){
	.g-recaptcha{
		margin-bottom:-13px;
		transform:scale(0.85);
		transform-origin:top left;
	}
}